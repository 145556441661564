<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <span style="margin-right: 25px">
          订单状态：
          <a-tag :color="this.form.order_status === 1 ? '#4cd964' : '#ff4d4f'" v-if="this.form.order_status">
            {{ this.form.order_status ? GLOBAL.wmsOrderStatus[this.form.order_status].name : '' }}
          </a-tag>
        </span>
        <a-button :disabled="!isEdit" @click="handleExport">出库单</a-button>
        <a-divider type="vertical" />
        <a-button>
          <router-link :to="{ name: 'Sell' }">取消</router-link>
        </a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit" :disabled="saveButtonDisbaled">保存</a-button>
      </div>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="出库单号">
              <a-input placeholder="出库单号自动生成" disabled v-model.trim="form.sell_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="出货时间" prop="out_date">
              <a-date-picker style="width: 100%" show-time format="YYYY/MM/DD HH:mm:ss" v-model="form.out_date" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="购买单位" prop="buyer">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="最晚送货时间">
              <a-date-picker style="width: 100%" show-time format="YYYY/MM/DD HH:mm:ss" v-model="form.latest_delivery_time" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="订单类型">
              <a-select
                style="width: 100%"
                v-model="form.order_type"
                @change="orderTypeChange"
              >
                <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="代理费">
              <a-input-number :disabled="form.lock_agent_fee" style="width: 100%" v-model="form.agency_fee" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="收费类型">
              <a-select :disabled="form.lock_charge_type" style="width: 100%" v-model="form.charge_type">
                <a-select-option :value="1">现金</a-select-option>
                <a-select-option :value="2">月结</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="订购人">
              <a-input v-model.trim="form.order_person" placeholder="请输入订购人" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="订购电话">
              <a-input v-model.trim="form.order_phone" placeholder="请输入订购电话" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="推荐人">
              <a-input v-model.trim="form.recommender" placeholder="请输入推荐人" />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="送货方式">
              <a-select
                v-model="form.escort_mode"
                allowClear
                placeholder="请选择送货方式"
              >
                <a-select-option v-for="op in escortModeOps" :key="op.id">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea v-model.trim="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-card class="card" title="地址信息" :bordered="false">
      <edit-table
        :columns="orderAddressRelationColumns"
        :sourceData="orderAddressRelationData"
        :scroll="true"
        :operate="{ add: true, del: true }"
        @getTableDate="getOrderAddressRelationData"
      ></edit-table>
    </a-card>

    <a-card class="card" title="快递信息" :bordered="false">
      <edit-table
        :columns="courierInfoColumns"
        :sourceData="courierInfoData"
        :scroll="true"
        :operate="{ add: true, del: true }"
        @getTableDate="getCourierInfoData"
        @getChangeData="getCourierChangeData"
      ></edit-table>
    </a-card>

    <a-card class="card" :bordered="false">
      <a-tabs :activeKey="activeKey" @change="key => { activeKey = key }">
        <a-tab-pane tab="货物信息" key="1">
          <edit-table
            :columns="goodsColumns"
            :sourceData="goodsData"
            :scroll="true"
            :operate="{ add: true, del: true, alldel: false }"
            @getTableDate="getGoodsData"
          ></edit-table>
        </a-tab-pane>
        <template slot="tabBarExtraContent">
          <a-button type="primary" v-if="activeKey === '1'" :disabled="!isEdit" @click="getPayQrcode">现金收费</a-button>
          <a-button type="primary" v-if="activeKey === '1'" @click="refundApply" style="margin-left: 20px;">退款申请</a-button>
          <span style="margin-left: 20px;">出货总价：{{ price_out_total.toFixed(2) }}</span>
        </template>
      </a-tabs>
    </a-card>

    <a-modal
      :visible="cashRecordVisible"
      :confirmLoading="cashRecordLoading"
      :width="1520"
      :maskClosable="false"
      title="现金收费记录"
      @cancel="cashRecordVisible = false"
      :footer="null"
    >
      <a-card style="margin-bottom: 10px">
        <a-radio-group name="radioGroup" v-model="cashType">
          <a-radio :value="1">微信</a-radio>
          <a-radio :value="2">支付宝</a-radio>
        </a-radio-group>
        <a-button type="primary" @click="createCashRecord">
          创建收款码
        </a-button>
      </a-card>
      <a-table :columns="cashColumns" :data-source="cashRecord" :pagination="false" row-key="id" size="small">
        <div slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </div>
        <div slot="pay_info" slot-scope="text, item">
          <img :src="item.pay_info" v-if="item.pay_info && item.is_expire === 0" style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
          <img src="~@/assets/invalid_code.jpg" v-else style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
        </div>
        <div slot="good_info" slot-scope="text">
          <div v-if="text">
            <span style="display:block" v-for="item in text" :key="item.id">{{ `${item.good_name}${item.good_spec} ${item.num}${item.good_unit} 合计：${item.charge}` }}</span>
          </div>
        </div>
        <div slot="action" slot-scope="text, item">
          <a v-if="item.cash_status === 0" @click="delCashRecord(item)">删除</a>
        </div>
      </a-table>
    </a-modal>

    <!-- 退款申请 -->
    <a-modal
      :visible="refundApplyVisible"
      :confirmLoading="refundApplyLoading"
      :width="1350"
      :maskClosable="false"
      title="退款申请"
      @ok="refundApplySubmit"
      @cancel="refundApplyCancel"
    >
      <a-form-model
        layout="vertical"
        ref="refundApplyForm"
        :model="refundApplyForm"
        :rules="refundApplyRule"
      >
        <a-row :gutter="24">
          <a-divider orientation="left">退款总金额
            <a-tag color="#f50" style="margin-left: 10px;">{{ refundAmountTotal.toFixed(2) }}</a-tag>
          </a-divider>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left">商品信息</a-divider>
          <edit-table
            :columns="refundApplyColumns"
            :sourceData="refundApplyData"
            @getTableDate="getRefundApplyData"
            :disabled="true"
          ></edit-table>
        </a-row>
        <a-divider orientation="left">其他信息</a-divider>
        <!-- 当订单产生了代理费、退款时才可以选择是否退代理费 -->
        <a-row :gutter="24" v-if="form.agency_fee > 0">
          <a-col :span="12">
            <a-form-model-item label="代理费是否退款">
              <a-select
                v-model="refundApplyForm.is_agent_fee_refund"
                style="width: 100%"
                allowClear
                @change="isAgentFeeRefundChange"
              >
                <a-select-option v-for="op in isAgentFeeRefundData" :key="op.value">
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="refundApplyForm.is_agent_fee_refund === 1">
            <a-form-model-item label="代理费退款金额">
              <a-input v-model="refundApplyForm.refund_agent_fee" disabled/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="申请退款原因" prop="apply_refund_reason">
              <a-textarea v-model="refundApplyForm.apply_refund_reason" allowClear />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import {
  createSell,
  getSellDetail,
  getGoodStockInfo,
  getAllReceivingAddress,
  getPurchaseNumList,
  getGoodStockByPurchaseId,
  listPayDealGoodInfo,
  sellRefundApply,
  initSellData
} from '@/api/wms/sale'
import {
  exportWmsSale,
  billCashRecord,
  delCashDeal,
  createCashQRCode
} from '@/api/wms'
import moment from 'moment'
import { EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import PayQrcode from '@/views/components/PayQrcode'
import { getCustomer, downloadExcel } from '@/api/common'
import debounce from 'lodash/debounce'

export default {
  components: {
    EditTable,
    PayQrcode
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.getAllReceivingAddress = debounce(this.getAllReceivingAddress, 800)

    return {
      saveButtonDisbaled: false,
      confirmLoading: false,
      isEdit: false,
      activeKey: '1',
      form: {
        sell_num: '',
        out_date: null,
        latest_delivery_time: null,
        buyer: null,
        remark: '',
        agency_fee: 0,
        charge_type: 2,
        order_type: 1
      },
      rules: {
        out_date: [{ required: true, message: '请选择出货时间', trigger: 'change' }],
        buyer: [{ required: true, message: '请选择购买单位', trigger: 'blur' }]
      },
      orderTypeOps: [], // 订单类型
      buyerOps: [], // 购买单位
      addressOps: [], // 送货地址
      escortModeOps: [], // 送货方式
      customerInfo: undefined,
      fetching: false,
      goodsData: [],
      stockGoodList: [],
      stockGoodOps: [],
      goodNoOps: [],
      goodsColumns: [
        {
          title: '进货单',
          dataIndex: 'purchase_num',
          width: 200,
          extra: {
            type: 'select',
            options: [],
            extraFunc: this.purchaseNumChange,
            disabled: record => {
              // status = 1 表示订单已经提交财商审核或此订单已经收取过费用
              // shipment_status !== 0 表示订单已经发货
              // 订单提交财商审核、收取费用、已发货均不能修改货物信息
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null;
                  for (const item of this.stockGoodList) {
                    if (item.id === val) {
                      value = item;
                    }
                  }
                  this.goodsData[index]['stock_id'] = value.id;
                  this.goodsData[index]['purchase_num'] = value.purchase_num;
                  this.goodsData[index]['good_id'] = value.good_id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  this.goodsData[index]['price_in'] = value.unit_price;
                  const data = [];
                  // 动态为 出货单价改变数据结构为 key: value 的形式
                  value.price_out.forEach(v => {
                    data.push({ name: v, value: v });
                  });
                  this.goodsColumns[7].extra.options = data;
                  this.goodsColumns = [...this.goodsColumns];
                  this.goodsData[index]['surplus_num'] = value.surplus_num;
                  this.goodsData[index]['supplier_id'] = value.supplier_id;
                  this.goodsData[index]['supplier_name'] = value.supplier_name;
                }}
              >
                {this.goodNoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 300,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null
                  for (const item of this.stockGoodList) {
                    if (item.id === val) {
                      value = item
                    }
                  }
                  this.goodsData[index]['stock_id'] = value.id;
                  this.goodsData[index]['purchase_num'] = value.purchase_num;
                  this.goodsData[index]['good_id'] = value.good_id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  this.goodsData[index]['price_in'] = value.unit_price;
                  const data = [];
                  // 动态为 出货单价改变数据结构为 key: value 的形式
                  value.price_out.forEach(v => {
                    data.push({ name: v, value: v });
                  });
                  this.goodsColumns[7].extra.options = data;
                  this.goodsColumns = [...this.goodsColumns];
                  this.goodsData[index]['surplus_num'] = value.surplus_num;
                  this.goodsData[index]['supplier_id'] = value.supplier_id;
                  this.goodsData[index]['supplier_name'] = value.supplier_name;
                }}
              >
                {this.stockGoodOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 60,
          extra: {
            type: 'text',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '库存数量',
          dataIndex: 'surplus_num',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100,
          extra: {
            type: `select`,
            options: [],
            extraFunc: this.calcTotalPrice,
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                disabled={record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)}
                min={0}
                onChange={val => {
                  this.goodsData[index]['num'] = val
                  if (val && this.goodsData[index]['price_out']) {
                    this.goodsData[index]['charge'] = (val * this.goodsData[index]['price_out']).toFixed(2)
                  } else {
                    this.goodsData[index]['charge'] = 0
                  }
                  let money = 0;
                  this.goodsData.forEach(v => {
                    money += parseFloat(v.charge);
                  });
                  this.price_out_total = money;
                }}
              />
            )
          }
        },
        {
          title: '出货总价',
          dataIndex: 'charge',
          width: 120,
          extra: {
            type: 'text',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '打包销售名称',
          dataIndex: 'package_sell_name',
          width: 200,
          extra: {
            type: 'select',
            options: [
              { 'name': '无', 'value': '' },
              { 'name': '远洋蓝精酿全家福啤酒', 'value': '远洋蓝精酿全家福啤酒' }
            ],
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '交易号',
          dataIndex: 'trx_num',
          width: 120,
          extra: {
            type: 'text',
            disabled: record => {
              return true
            }
          }
        },
        {
          title: `交易时间`,
          dataIndex: `pay_time`,
          width: 150,
          extra: {
            type: `text`,
            disabled: record => {
              return true
            }
          }
        },
        {
          title: `供应商`,
          dataIndex: `supplier_name`,
          width: 150,
          extra: {
            type: `text`,
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120,
          extra: {
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        }
      ],
      price_out_total: 0,
      cashRecordVisible: false,
      cashRecordLoading: false,
      cashRecord: [],
      cashType: 1,
      cashColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '二维码',
          dataIndex: 'pay_info',
          scopedSlots: { customRender: 'pay_info' }
        },
        {
          title: '收费类型',
          dataIndex: 'deal_type',
          customRender: (text) => {
            if (text === 0) {
              return '未知'
            }
            if (text === 1) {
              return '微信'
            }
            if (text === 2) {
              return '支付宝'
            }
          }
        },
        {
          title: '交易状态',
          dataIndex: 'cash_status',
          customRender: (text, record) => {
            if (text === 1) {
              return '已完成'
            }
            if (text === 0) {
              if (record.is_expire === 1) {
                return '已过期'
              } else {
                return '待支付'
              }
            }
          }
        },
        {
          title: '货物信息',
          dataIndex: 'good_out_list',
          scopedSlots: { customRender: 'good_info' }
        },
        {
          title: '金额',
          dataIndex: 'amount'
        },
        {
          title: '交易号',
          dataIndex: 'trx_id'
        },
        {
          title: '完成时间',
          dataIndex: 'pay_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 货物信息新增按钮是否禁用
      add_button_disabled: false,
      courierInfoColumns: [
        {
          title: '快递单号',
          width: 350,
          dataIndex: 'courier_no',
          extra: { type: 'input' }
        },
        {
          title: '发货日期',
          width: 150,
          dataIndex: 'send_good_date',
          extra: {
            type: 'date',
            default: null
          }
        },
        {
          title: '包裹数量',
          dataIndex: 'package_num',
          extra: { type: 'number' }
        },
        {
          title: '计费重量',
          dataIndex: 'weight',
          extra: { type: 'number' }
        },
        {
          title: '快递费',
          dataIndex: 'courier_fee',
          extra: { type: 'number' }
        },
        {
          title: '包装费',
          dataIndex: 'package_fee',
          extra: { type: 'number' }
        },
        {
          title: '保险费',
          dataIndex: 'safe_fee',
          extra: { type: 'number' }
        },
        {
          title: '资源调节费',
          dataIndex: 'resource_fee',
          extra: { type: 'number' }
        }
      ],
      courierInfoData: [],
      refundApplyVisible: false,
      refundApplyLoading: false,
      refundApplyColumns: [
        {
          title: '交易号',
          dataIndex: 'trx_id',
          width: 120,
          extra: {
            type: 'text'
          }
        },
        {
          title: '交易时间',
          dataIndex: 'pay_date',
          width: 120,
          extra: {
            type: 'text'
          }
        },
        {
          title: '交易状态',
          dataIndex: 'cash_status',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物信息',
          dataIndex: 'good_info',
          width: 200,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物卖出价格',
          dataIndex: 'price_out',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物数量(已支付)',
          dataIndex: 'good_num',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物数量(剩余可退款)',
          dataIndex: 'after_refund_num',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '退款数量',
          dataIndex: 'refund_num',
          width: 80,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width: 100%"
                value={text}
                min={0}
                onChange={val => {
                  this.refundApplyData[index]['refund_num'] = val
                  if (val > this.refundApplyData[index]['after_refund_num']) {
                    this.$notification['error']({
                      message: '提示',
                      description: '要退款的数量不能超过剩余可退数量'
                    })
                  } else {
                    if (val && this.refundApplyData[index]['price_out']) {
                      this.refundApplyData[index]['refund_price'] = (val * this.refundApplyData[index]['price_out']).toFixed(2)
                    } else {
                      this.refundApplyData[index]['refund_price'] = 0
                    }
                    let money = 0
                    this.refundApplyData.forEach(v => {
                      money += parseFloat(v.refund_price)
                    })
                    this.refundAmountTotal = money
                  }
                }}
              ></a-input-number>
            )
          }
        },
        {
          title: '退款总价',
          dataIndex: 'refund_price',
          width: 80,
          extra: {
            type: 'text'
          }
        }
      ],
      refundApplyData: [],
      // 退款总金额
      refundAmountTotal: 0,
      refundApplyForm: {
        is_agent_fee_refund: 0,
        refund_agent_fee: 0,
        apply_refund_reason: null
      },
      refundApplyRule: {
        apply_refund_reason: [{ required: true, message: '请填写申请退款原因', trigger: 'click' }]
      },
      // 代理费是否退款
      isAgentFeeRefundData: [
        { value: 0, name: '否' },
        { value: 1, name: '是' }
      ],
      orderAddressRelationColumns: [
        {
          title: '送货地址',
          dataIndex: 'address_str',
          width: 300,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.status === 1}
                filterOption={this.filterOption}
                onSearch={val => this.getAllReceivingAddress(val)}
                onChange={val => {
                  let current = null
                  for (const item of this.addressOps) {
                    if (item.id === val) {
                      current = item
                      break
                    }
                  }
                  this.orderAddressRelationData[index]['address_id'] = current['id']
                  this.orderAddressRelationData[index]['address_str'] = current['receiving_address'] + '/' + current['receiving_person'] + '/' + current['phone']
                }}
              >
                {this.addressOps.map(op => {
                  return <a-select-option key={op.id}>
                  {op.receiving_address}/{op.receiving_person}/{op.phone}
                  </a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '送货方式',
          dataIndex: 'escort_mode_str',
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                allowClear
                disabled={record.status === 1}
                onChange={val => {
                  let current = null
                  for (const item of this.escortModeOps) {
                    if (item.id === val) {
                      current = item
                      break
                    }
                  }
                  this.orderAddressRelationData[index]['escort_mode'] = current['id']
                  this.orderAddressRelationData[index]['escort_mode_str'] = current['name']
                }}
              >
                {this.escortModeOps.map(op => {
                  return <a-select-option key={op.id}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        }
      ],
      orderAddressRelationData: []
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'SellCUR') {
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          this.isEdit = false
          this.$route.meta.title = '新增出货信息'
          this.add_button_disabled = false;
        } else {
          this.isEdit = true
          this.$route.meta.title = '编辑出货信息'
          this.customerInfo = {
            key: newRoute.params.buyer,
            label: newRoute.params.buyer_name
          }
          if (newRoute.params.order_type && newRoute.params.order_type === 5) {
            this.goodsColumns[7] = {
              title: '出货单价',
              dataIndex: 'price_out',
              width: 100,
              extra: {
                type: 'input-number',
                disabled: record => {
                  return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
                }
              }
            }
          } else {
            this.goodsColumns[7] = {
              title: '出货单价',
              dataIndex: 'price_out',
              width: 100,
              extra: {
                type: 'select',
                options: [],
                extraFunc: this.calcTotalPrice,
                disabled: record => {
                  return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
                }
              }
            }
          }
          if (newRoute.params.shipment_status === 1) {
            // 发货状态则禁用新增按钮
            this.add_button_disabled = true;
          } else {
            this.add_button_disabled = false;
          }
          this.getSellOtherInfo(newRoute.params.id)
          this.getGoodStockInfo();
        }
      }
    }
  },
  mounted() {
    this.handleFormReset()
    if (this.$route.params.id !== 'create') {
      this.isEdit = true
      this.$route.meta.title = '编辑出货信息'
      this.customerInfo = {
        key: this.$route.params.buyer,
        label: this.$route.params.buyer_name
      }
      if (this.$route.params.order_type && this.$route.params.order_type === 5) {
        this.goodsColumns[7] = {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100,
          extra: {
            type: 'input-number',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        }
      } else {
        this.goodsColumns[7] = {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100,
          extra: {
            type: 'select',
            options: [],
            extraFunc: this.calcTotalPrice,
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        }
      }
      if (this.$route.params.shipment_status === 1) {
        // 发货状态则禁用新增按钮
        this.add_button_disabled = true;
      } else {
        this.add_button_disabled = false;
      }
      this.getSellOtherInfo(this.$route.params.id)
      this.getGoodStockInfo();
    }
    initSellData().then(res => {
      this.orderTypeOps = res.WMS_ORDER_TYPE
      this.escortModeOps = res.ESCORT_MODE
    })
    this.getGoodStockInfo()
  },
  methods: {
    moment,
    getAllReceivingAddress(value) {
      getAllReceivingAddress({ 'value': value }).then(res => {
        this.addressOps = res
      })
    },
    getOrderAddressRelationData(data) {
      this.orderAddressRelationData = data
    },
    // 退款申请
    refundApply() {
      const reqParam = {
        'order_id': this.form.id,
        'order_type': 1
      }
      listPayDealGoodInfo(reqParam).then(res => {
        if (res.length > 0) {
          this.refundApplyData = res
          this.refundApplyVisible = true
        } else {
          this.$notification['warning']({
            message: '提示',
            description: '该订单未支付或货物已全部退款'
          })
        }
      })
    },
    refundApplyCancel() {
      this.refundApplyVisible = false
      this.refundApplyLoading = false
      this.refundApplyData = []
      this.refundAmountTotal = 0
      this.refundApplyForm = {
        is_agent_fee_refund: 0,
        refund_agent_fee: 0,
        apply_refund_reason: null
      }
    },
    refundApplySubmit() {
      this.refundApplyLoading = true
      this.$refs.refundApplyForm.validate(valid => {
        if (valid) {
          const refundOrderData = []
          this.refundApplyData.forEach(item => {
            if (item.refund_num > 0) {
              refundOrderData.push({
                'good_id': item.good_id,
                'good_num': item.refund_num
              })
            }
          })
          const reqParam = {
            'order_id': this.form.id,
            'apply_order_list': refundOrderData,
            'apply_refund_amount': this.refundAmountTotal,
            'apply_refund_reason': this.refundApplyForm.apply_refund_reason,
            'is_agent_fee_refund': this.refundApplyForm.is_agent_fee_refund
          }
          sellRefundApply(reqParam).then(res => {
            if (res) {
              this.$notification['success']({
                message: '提示',
                description: '退款申请创建成功'
              })
            } else {
              this.$notification['error']({
                message: '提示',
                description: '退款申请创建失败'
              })
            }
          }).finally(_ => {
            this.refundApplyCancel()
          })
        } else {
          this.refundApplyLoading = false
        }
      })
    },
    getRefundApplyData(data) {
      this.refundApplyData = data
    },
    // 代理费是否退款
    isAgentFeeRefundChange() {
      if (this.refundApplyForm.is_agent_fee_refund === 1) {
        this.refundApplyForm.refund_agent_fee = this.form.agency_fee
        if (this.refundApplyForm.refund_agent_fee > 0) {
          this.refundAmountTotal = parseFloat(this.refundAmountTotal + this.refundApplyForm.refund_agent_fee)
        }
      } else {
        this.refundAmountTotal = 0
      }
    },
    orderTypeChange() {
      if (this.form.order_type === 5) {
        // 订单类型选择饭卡将出货单价改为填写
        this.goodsColumns[7] = {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100,
          extra: {
            type: 'input-number',
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        }
      } else {
        // 订单类型选择其他将出货类型改为下拉
        this.goodsColumns[7] = {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 100,
          extra: {
            type: 'select',
            options: [],
            extraFunc: this.calcTotalPrice,
            disabled: record => {
              return record.status === 1 || (record.shipment_status !== undefined && record.shipment_status !== 0)
            }
          }
        }
      }
    },
    getGoodStockInfo() {
      getGoodStockInfo().then(res => {
        this.stockGoodList = res
        this.stockGoodOps = res.map(e => {
          return {
            name: `${e.good_name}/${e.good_spec}/${e.purchase_num}`,
            value: e.id
          }
        });
        this.goodNoOps = res.filter(e => e.good_no && e.good_no !== '').map(e => {
          return {
            name: e.good_no,
            value: e.id
          }
        })
      });
    },
    purchaseNumChange(value, newData, target, index) {
      const goodIds = this.goodsData.map(v => v.good_id);
      return getGoodStockByPurchaseId({
        purchaseId: value
      }).then(res => {
        const data = res.map(item => {
          return {
            stock_id: item.id,
            purchase_num: item.purchase_num,
            good_id: item.good_id,
            good_no: item.good_no,
            good_name: item.good_name,
            good_unit: item.good_unit,
            good_spec: item.good_spec,
            surplus_num: item.surplus_num,
            price_in: item.unit_price,
            price_out: 0,
            num: 0,
            charge: 0,
            remark: undefined,
            charge_type: 2,
            supplier_id: item.supplier_id
          }
        }).filter(f => !goodIds.includes(f.good_id));
        newData.splice(newData.length - 1, 1);
        this.goodsData = [ ...newData, ...data ];
        return [ ...newData, ...data ];
      });
    },
    supplierChange(value) {
      if (value !== undefined) {
        getPurchaseNumList({
          supplierId: value
        }).then(res => {
          this.goodsColumns[0].extra.options = res;
          this.goodsColumns = [
            ...this.goodsColumns
          ]
        });
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.buyerOps = []
      this.fetching = true
      getCustomer({
        department: 8,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.buyerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.form['buyer'] = value.key
        this.form['buyer_name'] = value.label
      } else {
        this.customerInfo = undefined
        this.form['buyer'] = null
        this.form['buyer_name'] = null
      }
      if (!this.form.lock_charge_type) {
        if (value.label === '个人') {
          this.form.charge_type = 1;
        } else {
          this.form.charge_type = 2;
        }
      }
      this.buyerOps = []
      this.fetching = false
    },
    handleFormReset() {
      this.form = {
        sell_num: '',
        out_date: null,
        latest_delivery_time: null,
        buyer: null,
        remark: '',
        agency_fee: 0,
        charge_type: 2,
        order_type: 1
      }
      this.goodsData = []
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      });
      this.price_out_total = 0;
      this.customerInfo = undefined;
      this.orderAddressRelationData = []
      this.courierInfoData = []
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getSellOtherInfo(id) {
      getSellDetail({
        id: id
      }).then(res => {
        this.form = res.sell
        for (const item of res.good_out_list) {
          if (item.status === 1) {
            item.disabled = true
          }
        }
        this.goodsData = res.good_out_list
        this.price_out_total = 0
        this.goodsData.forEach(v => {
          this.price_out_total += parseFloat(v.charge);
        });
        this.courierInfoData = res.sell.courier_info_list
        this.orderAddressRelationData = res.sell.order_address_relation_list
      })
    },
    getGoodsData(data) {
      this.goodsData = data;
      let money = 0;
      this.goodsData.forEach(v => {
        money += parseFloat(v.charge);
      });
      this.price_out_total = money;
    },
    calcTotalPrice(value, newData, target) {
      if (target.price_out && target.num) {
        target['charge'] = (target.price_out * target.num).toFixed(2)
      } else {
        target['charge'] = 0
      }
      let money = 0;
      this.goodsData.forEach(v => {
        money += parseFloat(v.charge);
      });
      this.price_out_total = money;
      return newData
    },
    getPayQrcode() {
      this.cashRecordVisible = true
      this.getCashRecord()
    },
    getCashRecord() {
      this.cashRecordLoading = true
      billCashRecord({
        id: this.form.id,
        billType: 1
      }).then(res => {
        this.cashRecord = res
      }).finally(_ => {
        this.cashRecordLoading = false
      })
    },
    delCashRecord(item) {
      delCashDeal({
        cashId: item.id
      }).then(res => {
        if (res) {
          this.getCashRecord()
          this.getSellOtherInfo(this.form.id)
        }
      })
    },
    createCashRecord() {
      createCashQRCode({
        id: this.form.id,
        billType: 1,
        dealType: this.cashType
      }).then(res => {
        if (res) {
          this.getCashRecord()
          this.getSellOtherInfo(this.form.id)
        }
      })
    },
    handleExport() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出出库单吗？',
        onOk: () => {
          exportWmsSale({ id: this.form.id }).then(res => {
            if (res !== null) {
              const fileInfo = {
                fileName: res
              }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    handleSubmit() {
      this.saveButtonDisbaled = true
      this.confirmLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.courierInfoData.forEach(item => {
            if (item.send_good_date) {
              item.send_good_date = moment(item.send_good_date).valueOf()
            }
          })
          const sellInfo = {
            sell: {
              ...this.form,
              out_date: this.form.out_date ? moment(this.form.out_date).valueOf() : null,
              latest_delivery_time: this.form.latest_delivery_time ? moment(this.form.latest_delivery_time).valueOf() : null,
              courier_info_list: this.courierInfoData,
              order_address_relation_list: this.orderAddressRelationData
            },
            good_out_list: this.goodsData
          }
          createSell(sellInfo).then(v => {
            this.formVisible = false
            this.confirmLoading = false
            this.saveButtonDisbaled = false
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            this.getSellOtherInfo(v);
            this.getGoodStockInfo();
          }).catch(_ => {
            this.saveButtonDisbaled = false
          })
        } else {
          this.confirmLoading = false
          this.saveButtonDisbaled = false
        }
      })
    },
    getCourierInfoData(data) {
      this.courierInfoData = data
    },
    getCourierChangeData(data) {
      if (data.column === 'send_good_date') {
        this.$set(this.courierInfoData, data.index, {
          ...this.courierInfoData[data.index],
          'send_good_date': data.value
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}
</style>
